<template>
  <div>
    <v-container>
      <p class="display-1 font-weight-light	text-center pa-4">Read About Our Latest Updates</p>
      <div class="row">
        <div class="col-md-4 col-sm-4 col-xs-12" v-for="blog in blogs" :key="blog.id">
          <router-link :to="{ name: 'Post', params: {blog: blog } }">
            <v-card class="mx-auto" max-width="400" outlined tile>
              <v-img class="white--text align-end" height="400px" :src="blog.featured_image?blog.featured_image:require('@/assets/img/home/slider2.jpg')">
                <v-card-title class="text-warning">{{blog.title}}</v-card-title>
              </v-img>

              <v-card-subtitle class="pb-0">{{ blog.title }}</v-card-subtitle>

              <v-card-text class="text--primary">

                <div>{{ blog.excerpt}}</div>
              </v-card-text>

              <v-card-actions>
                <v-btn color="orange" text :to="{ name: 'Post', params: { blog: blog } }">
                  Read More
                </v-btn>
              </v-card-actions>
            </v-card>
          </router-link>
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
import axios from "@/Axiosconfig"

export default {
  data() {
    return {
      blogs:[]
    }
  },
  mounted() {
    this.updatearrays();
  },
  methods: {
    updatearrays() {
      axios.get(window.$http+`blog`,{headers:window.$headers})
        .then(response => {
          this.blogs=response.data['results']
        }).catch(e => {
        console.log(e)
      })
    },
  },
}
</script>